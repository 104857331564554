import moment from 'moment';

export const getSuggestions = async (searchedText) => {
    return fetch(`https://svcchronomobile.artm.quebec/ws/planificateur/lieux/suggestions?TermeRecherche=${searchedText}&Langue=fr`,

    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
    .then( async data => {
        return await data.json();
    })
}

export const getItinerary = async (values) => {

    const { destinationAddress, startingAddress, language, travelBus, travelMetro, travelTrain, travelTime, travelDate, departureOrArrival } = values;
    

    let dateStr;
    let timeStr;
    let date;
    let time;

    if (!travelDate && !travelTime) {
        dateStr = moment().format('YYYY-MM-DD');
        timeStr = moment().format('HH:mm');
        date = moment(dateStr);
        time = moment(timeStr, 'HH:mm');
    }

    if (travelDate && !travelTime) {
        dateStr = moment(travelDate).format('YYYY-MM-DD');
        timeStr = moment().format('HH:mm');
        date = moment(dateStr);
        time = moment(timeStr, 'HH:mm');
    }

    if (!travelDate && travelTime) {
        dateStr = moment().format('YYYY-MM-DD');
        timeStr = moment(travelTime).format('HH:mm');
        date = moment(dateStr);
        time = moment(timeStr, 'HH:mm');
    }

    if (travelDate && travelTime) {
        dateStr = moment(travelDate).format('YYYY-MM-DD');
        timeStr = moment(travelTime).format('HH:mm');
        date = moment(dateStr);
        time = moment(timeStr, 'HH:mm');
    }

    date.set({
        hour:   time.get('hour'),
        minute: time.get('minute'),
        second: time.get('second')
    })

    let toUnix = date.unix();
    let final = toUnix.valueOf();

    if (departureOrArrival === "arrivée") {
        return fetch(`https://svcchronomobile.artm.quebec/ws/planificateur/trajets?OrigineLieuId=${startingAddress}&DestinationLieuId=${destinationAddress}&Langue=${language}&UtiliserMetro=${travelMetro}&UtiliserBus=${travelBus}&UtiliserTrain=${travelTrain}&HeureArriveeUTC=${final}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
            .then( async data => {
                return await data.json();
        })
    }

    return fetch(`https://svcchronomobile.artm.quebec/ws/planificateur/trajets?OrigineLieuId=${startingAddress}&DestinationLieuId=${destinationAddress}&Langue=${language}&UtiliserMetro=${travelMetro}&UtiliserBus=${travelBus}&UtiliserTrain=${travelTrain}&HeureDepartUTC=${final}`,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
    .then( async data => {
        return await data.json();
    })
}